import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Box,
  Container,
} from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "src/component/Logo";
import { AuthContext } from "src/context/Auth";
import { currencyUnit } from "src/constants";
import ConnectWalletModal from "src/component/ConnectWalletModal";
import { MdOutlineArrowBackIos } from "react-icons/md";
import FirstTimeReferralModal from "src/component/FirstTimeReferralModal";
import SwipeableTemporaryDrawer from "./RightDrawer";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: " 5px 0px",
    "& .TopbarButton": {
      marginRight: "0px !important",
      margin: "0px !important",
    },
  },
}));

export default function Header() {
  const { toolbar } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.userData.refferalCodeCheck) {
      auth.setOpenReferralModal(true);
    }
  }, [auth.userData.refferalCodeCheck]);

  useEffect(() => {
    if (location) {
      window.localStorage.setItem(
        "referralCode",
        location && location?.search?.split("?")[1]
      );
    }
  }, [location]);

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{
          backgroundColor: "#191B30",
          borderBottom: "1px solid #2C2C2C",
        }}
        className="hedaerTopbar"
      >
        <Container maxWidth="fixed">
          <Toolbar className={toolbar}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <IconButton
                variant="contained"
                color="secondary"
                size="small"
                style={{ whiteSpace: "pre" }}
                onClick={() =>
                  window.location.pathname === "/leaderboard"
                    ? history.push("/derby")
                    : window.location.pathname === "/privacy-policy" ||
                      window.location.pathname === "/terms-condition"
                    ? history.push("/")
                    : history.goBack()
                }
              >
                <MdOutlineArrowBackIos />
              </IconButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {/* <IconButton
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => history.push("/")}
              >
                <AiFillHome />
              </IconButton> */}
              <Link to="/">
                <Logo className="logoImg" />
              </Link>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              {/* <Box className="centerLogoBox">
                <Link to="/">
                  <Logo className="logoImg" />
                </Link>
              </Box> */}
              {auth.userLoggedIn && (
                <Box className="displayCenter" style={{ gap: "7px" }}>
                  <Button
                    variant="contained"
                    className="TopbarButton"
                    color="secondary"
                    style={{
                      whiteSpace: "pre",
                      padding: "10px 13px",
                      height: "auto",
                    }}
                  >
                    <img
                      src="images/walletIcon.svg"
                      alt="iamge"
                      height="26px"
                    />
                    &nbsp;
                    {auth?.blcLoad
                      ? "..."
                      : auth?.viewWalletData?.balance
                      ? parseFloat(auth?.viewWalletData?.balance).toFixed(2)
                      : "0"}{" "}
                    {currencyUnit}
                  </Button>
                  <Box style={{ marginRight: "8px" }}>
                    <SwipeableTemporaryDrawer />
                  </Box>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <ConnectWalletModal
        handleCloseModal={() => auth.setOpenMetamask(false)}
        openModal={auth.openMetamask}
        auth={auth}
      />
      <FirstTimeReferralModal
        handleCloseModal={() => auth.setOpenReferralModal(false)}
        openModal={auth.openReferralModal}
        auth={auth}
      />
    </>
  );
}
