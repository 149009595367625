import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Hidden,
} from "@material-ui/core";
import ConnectWalletModal from "src/component/ConnectWalletModal";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { AuthContext } from "src/context/Auth";
import { MdDashboard } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import FirstTimeReferralModal from "src/component/FirstTimeReferralModal";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AuthModal from "./AuthModal";
import Slide from "@material-ui/core/Slide";
import { currencyUnit } from "src/constants";
import { setCryptoDecimals } from "src/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },

  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    // background: theme.palette.primary.main,
    height: 266,
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  buttonBoxes: {
    display: "flex",
    alignItems: "center",
    marginRight: "23px",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (auth.userData.refferalCodeCheck) {
      auth.setOpenReferralModal(true);
    }
  }, [auth.userData.refferalCodeCheck]);
  useEffect(() => {
    if (location.search === "?login" && !auth.userLoggedIn) {
      setOpen(true);
    }
  }, [location]);

  useEffect(() => {
    if (location && location?.search?.split("?")[1] !== "login") {
      window.localStorage.setItem(
        "referralCode",
        location && location?.search?.split("?")[1]
      );
    }
  }, [location]);

  return (
    <>
      <Box flexGrow={1} />
      <Box className={classes.buttonBoxes}>
        <Hidden xsDown>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // const url = process.env.FREE_PLAY_URL;
              const url = process.env.REACT_APP_FREE_URL;
              const checkUrl = auth?.userData?.email
                ? `${url}/?email=${auth?.userData?.email}`
                : url;
              window.open(checkUrl);
            }}
          >
            Switch to freeplay
          </Button>
          &nbsp;
        </Hidden>
        {auth.userLoggedIn && (
          <Box className="displayCenter" style={{ gap: "5px" }}>
            <Button
              variant="contained"
              className="TopbarButton"
              color="secondary"
              style={{
                whiteSpace: "pre",
                padding: "10.5px 13px",
                height: "auto",
              }}
            >
              <img src="images/walletIcon.svg" alt="iamge" height="26px" />
              &nbsp;
              {auth?.blcLoad
                ? "..."
                : auth?.viewWalletData?.balance
                ? parseFloat(auth?.viewWalletData?.balance).toFixed(2)
                : "0"}{" "}
              {currencyUnit}
            </Button>
            <Box style={{ marginRight: "8px" }}>
              <SwipeableTemporaryDrawer />
            </Box>
          </Box>
        )}
        {auth.userData.userType === "ADMIN" && (
          <IconButton
            onClick={() => {
              localStorage.setItem("ADMIN", true);
              history.push("/admin-dashboard");
            }}
            mr={2}
            style={{
              background: "#f4a91b",
              color: "#fff",
              padding: "4px",
              marginRight: "10px",
            }}
          >
            <MdDashboard />
          </IconButton>
        )}
        {!auth.userLoggedIn && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => setOpen(true)}
          >
            Login
          </Button>
        )}
      </Box>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          onClose={() => {
            setOpen(false);
          }}
          maxWidth="lg"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <AuthModal closeBox={() => setOpen(false)} />
          </DialogContent>
        </Dialog>
      )}
      <ConnectWalletModal
        handleCloseModal={() => auth.setOpenMetamask(false)}
        openModal={auth.openMetamask}
        auth={auth}
      />
      <FirstTimeReferralModal
        handleCloseModal={() => auth.setOpenReferralModal(false)}
        openModal={auth.openReferralModal}
        auth={auth}
      />
    </>
  );
}
